<template>
    <section class="login_page">
        <div class="container">
            <div class="row d-flex align-items-center">
                <div class="col-md-7">
                    <div class="login_bg">
                        <figure  class="form-cover">
                            <img width="90%"  src="https://www.brightskills.com/_nuxt/img/form-image.725e2c5.svg" alt="form-cover-image" class="w-fluid"/>
                        </figure>

                    </div>
                </div>
                <div class="col-md-5">
                    <div  class="bskills-card m-auto register">
                        <a  href="/" class="bskills-card-cap nuxt-link-active"><img  src="https://www.brightskills.com/_nuxt/img/logo.b577065.svg" alt="company-logo" /></a>
                        <div  class="common_header">
                            <h3  class="common_heading_padding">সাইন ইন করুন</h3>
                            <p  class="common_text_padding">
                                রেজিস্ট্রেশনের সময় যে ই-মেইল এড্রেস এবং পাসওয়ার্ড দিয়ে অ্যাকাউন্ট ওপেন করেছেন সেই একই তথ্য দিয়ে সাইন ইন করুন।
                            </p>
                        </div>
                        <div  class="bskills-card-body w-100">
                            <form  @click.prevent class="bskills-form">
                                
                                <div  class="inputbox"><input type="text" placeholder="নাম" class="input-field"></div>
                                <div  class="inputbox"><input  type="email" placeholder="ইমেইল" class="input-field w-100" /></div>
                                <div class="inputbox"><input type="tel" placeholder="মোবাইল" class="input-field"></div>
                                <div  class="inputbox"><input  type="password" placeholder="পাসওয়ার্ড" class="input-field" /></div>
                                <div  class="inputbox"><input type="password" placeholder="কনফার্ম পাসওয়ার্ড" class="input-field"></div>
                                <div  class="submitbox"><button  type="submit" class="btn-submit"
                                    @click="showAlert"
                                    >সাইন ইন</button></div>
                            </form>
                            <div  class="bskills-social">
                                <h4  class="title">পাসওয়ার্ড ভুলে গেলে <a  href="/forgot-password" class="">এখানে</a> ক্লিক করুন</h4>
                                <h3  class="title mt-3">অথবা সাইন ইন করুন</h3>
                                <ul  class="social-list">
                                    <li  class="social-list-item">
                                        <button  type="button" class="btn-social">
                                            <svg  xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="48" height="48" viewBox="0 0 48 48" style="fill: rgb(0, 0, 0);">
                                                <path
                                                    fill="#FFC107"
                                                    d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                                                ></path>
                                                <path
                                                    
                                                    fill="#FF3D00"
                                                    d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                                                ></path>
                                                <path  fill="#4CAF50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"></path>
                                                <path
                                                    
                                                    fill="#1976D2"
                                                    d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                                                ></path>
                                            </svg>
                                        </button>
                                    </li>
                                    <li  class="social-list-item">
                                        <button  type="button" class="btn-social">
                                            <svg  width="15" height="30" viewBox="0 0 15 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    
                                                    fill-rule="evenodd"
                                                    clip-rule="evenodd"
                                                    d="M3.67602 15.3559V29.4085C3.67602 29.6128 3.8359 29.7727 4.04021 29.7727H9.2544C9.4587 29.7727 9.61859 29.6128 9.61859 29.4085V15.125H13.4026C13.5892 15.125 13.7491 14.9829 13.7668 14.7874L14.131 10.4882C14.1488 10.275 13.98 10.0885 13.7668 10.0885H9.61859V7.04166C9.61859 6.32216 10.196 5.74478 10.9155 5.74478H13.829C14.0333 5.74478 14.1932 5.58489 14.1932 5.38059V1.07245C14.1932 0.868142 14.0333 0.708252 13.829 0.708252H8.89908C6.01218 0.708252 3.66713 3.0533 3.66713 5.9402V10.0885H1.0556C0.851293 10.0885 0.691406 10.2483 0.691406 10.4526V14.7519C0.691406 14.9562 0.851293 15.1161 1.0556 15.1161H3.66713V15.3559H3.67602Z"
                                                    fill="#3C5A99"
                                                ></path>
                                            </svg>
                                        </button>
                                    </li>
                                </ul>
                                <p  class="social-text">যদি কোন একাউন্ট  থাকে তাহলে <a  href="/signup" class="link">সাইন ইন  করুন</a></p>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default{
    methods: {
        showAlert() {
        this.$swal.fire(
            {
            title: '<strong>Sorry for the inconvenience. Our website is currently undergoing scheduled maintenance. We apologize for any inconvenience this may cause. Please check back later</strong>',
            icon: 'info',
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonText:false,
            cancelButtonText:false,
            padding: '3em',
            width: 600,
            }
)
    },
    },
}
</script>