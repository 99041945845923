<template>
  <div>
    <section
      id="single-banner"
      v-if="$router.currentRoute.path == '/course-category'"
    >
      <div class="container">
        <div class="row">
          <div class="col-xl-8 m-auto">
            <div class="single_banner_content">
              <h3>“গ্রাফিক্স ডিজাইন" সার্চ রেজাল্ট</h3>
              <p class="ps-2"><b>২০ রেজাল্ট ফাউন্ড</b></p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      id="single-banner"
      v-if="
        $router.currentRoute.path == '/search' ||
        $router.currentRoute.path == '/single-course-category' ||
        $router.currentRoute.path == '/all-course'
      "
    >
      <div class="container">
        <div class="row">
          <div class="col-xl-8">
            <div
              class="single_banner_content"
              v-if="$router.currentRoute.path !== '/all-course'"
            >
              <h3>ডিজাইন</h3>
              <p>
                প্রযুক্তি নির্ভরতা বৃদ্ধির সাথে সাথে ডিজাইন সেক্টরে ক্যারিয়ার
                সুযোগ বিস্তৃত হচ্ছে। ডিজাইন সেক্টরে আপনার পছন্দের যেকোন একটি
                বিষয়ে স্কিল অর্জন করে ক্যারিয়ার ডেভেলপ করতে পারবেন। আপডেটেড
                কোর্স মডিউল অনুসরণ করে অভিজ্ঞ মেন্টর দ্বারা ডিজাইন সেক্টরের
                প্রতিটি কোর্স তৈরি করা হয়েছে।
              </p>
              <a href="#" v-for="(items, i) in 2" :key="i">লোগো ডিজাইন</a>
            </div>
            <div
              class="single_banner_content"
              v-if="$router.currentRoute.path == '/all-course'"
            >
              <h3>সকল কোর্স</h3>
              <p>
                ব্রাইট স্কিলস - এর প্রতিটি কোর্স এখানে ক্যাটেগরি ভিত্তিতে সাজানো
                হয়েছে। আপনার পছন্দের ক্যাটেগরিতে ক্লিক করলে রিলেটেড কোর্স এক
                নজরে দেখতে পাবেন।
              </p>
              <a href="#" v-for="(items, i) in 2" :key="i">লোগো ডিজাইন</a>
            </div>
          </div>
          <div class="col-xl-4"></div>
        </div>
      </div>
    </section>
    <section
      id="single_category_tabs_slider"
      v-if="
        $router.currentRoute.path == '/search' ||
        $router.currentRoute.path == '/single-course-category'
      "
    >
      <div class="container">
        <div class="row">
          <div class="col-xl-12">
            <div class="single_category_tabs">
              <ul class="nav nav-pills" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                  <div class="single_banner_content">
                    <div id="filter-navbar" class="d-xl-none d-lg-none mb-5">
                      <nav class="relative">
                        <div class="container-fluid">
                          <button
                            class="navbar-toggler"
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasDarkNavbar2"
                            aria-controls="offcanvasDarkNavbar"
                          >
                            <p class="filter_btn">Filter</p>
                          </button>
                          <div
                            class="offcanvas offcanvas-end text-dark"
                            tabindex="-1"
                            id="offcanvasDarkNavbar2"
                            aria-labelledby="offcanvasDarkNavbarLabel"
                          >
                            <div class="offcanvas-header">
                              <button
                                type="button"
                                class="btn-close btn-close-dark"
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div class="offcanvas-body">
                              <div class="row">
                                <div class="col-12">
                                  <div
                                    class="accordion filter_nav_back"
                                    id="accordionExample"
                                  >
                                    <div class="accordion-item">
                                      <h2
                                        class="accordion-header"
                                        id="headingOne"
                                      >
                                        <button
                                          class="accordion-button"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target="#collapseOne"
                                          aria-expanded="true"
                                          aria-controls="collapseOne"
                                        >
                                          <div class="accordion_name">
                                            <p>সাবক্যাটাগরি</p>
                                          </div>
                                        </button>
                                      </h2>
                                      <div
                                        id="collapseOne"
                                        class="accordion-collapse collapse show"
                                        aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample"
                                      >
                                        <div class="accordion-body">
                                          <div class="filter_box">
                                            <div class="filter_item">
                                              <input
                                                type="radio"
                                                name="subcategory"
                                                id="module"
                                                class="filterinput"
                                              />
                                              <label
                                                class="filterlabel"
                                                for="module"
                                                >ওয়েব ডিজাইন</label
                                              >
                                            </div>
                                            <div
                                              class="filter_item"
                                              v-for="(fItem, i) in 3"
                                              :key="i"
                                            >
                                              <input
                                                type="radio"
                                                name="subcategory"
                                                id="module3"
                                                class="filterinput"
                                              />
                                              <label
                                                class="filterlabel"
                                                for="module3"
                                                >ওয়েব ডিজাইন</label
                                              >
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="accordion-item">
                                      <h2
                                        class="accordion-header"
                                        id="headingOne"
                                      >
                                        <button
                                          class="accordion-button"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target="#collapsetwo"
                                          aria-expanded="true"
                                          aria-controls="collapseOne"
                                        >
                                          <div class="accordion_name">
                                            <p>রিভিউ</p>
                                          </div>
                                        </button>
                                      </h2>
                                      <div
                                        id="collapsetwo"
                                        class="accordion-collapse collapse show"
                                        aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample"
                                      >
                                        <div class="accordion-body">
                                          <div class="filter_box">
                                            <div
                                              class="filter_item"
                                              v-for="(fItem, i) in 3"
                                              :key="i"
                                            >
                                              <input
                                                type="radio"
                                                name="subcategory"
                                                id="module"
                                                class="filterinput"
                                              />
                                              <label
                                                class="starlabel"
                                                for="module"
                                                ><i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                              </label>
                                            </div>
                                            <div class="filter_item">
                                              <input
                                                type="radio"
                                                name="subcategory"
                                                id="module"
                                                class="filterinput"
                                              />
                                              <label
                                                class="starlabel"
                                                for="module"
                                                ><i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="accordion-item">
                                      <h2
                                        class="accordion-header"
                                        id="headingOne"
                                      >
                                        <button
                                          class="accordion-button"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target="#collapsefour"
                                          aria-expanded="true"
                                          aria-controls="collapseOne"
                                        >
                                          <div class="accordion_name">
                                            <p>প্রাইজ</p>
                                          </div>
                                        </button>
                                      </h2>
                                      <div
                                        id="collapsefour"
                                        class="accordion-collapse collapse show"
                                        aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample"
                                      >
                                        <div class="accordion-body">
                                          <div class="filter_box">
                                            <div class="filter_item">
                                              <input
                                                type="radio"
                                                name="subcategory"
                                                id="module"
                                                class="filterinput"
                                              />
                                              <label
                                                class="filterlabel"
                                                for="module"
                                                >পেইড (২৫০)</label
                                              >
                                            </div>
                                            <div class="filter_item">
                                              <input
                                                type="radio"
                                                name="subcategory"
                                                id="module"
                                                class="filterinput"
                                              />
                                              <label
                                                class="filterlabel"
                                                for="module"
                                                >ফ্রি (২৫)</label
                                              >
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="accordion-item">
                                      <h2
                                        class="accordion-header"
                                        id="headingOne"
                                      >
                                        <button
                                          class="accordion-button"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target="#collapsethree"
                                          aria-expanded="true"
                                          aria-controls="collapseOne"
                                        >
                                          <div class="accordion_name">
                                            <p>ইন্সট্রাকটর</p>
                                          </div>
                                        </button>
                                      </h2>
                                      <div
                                        id="collapsethree"
                                        class="accordion-collapse collapse show"
                                        aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample"
                                      >
                                        <div class="accordion-body">
                                          <div class="filter_box">
                                            <div class="filter_item">
                                              <input
                                                type="radio"
                                                name="subcategory"
                                                id="module"
                                                class="filterinput"
                                              />
                                              <label
                                                class="filterlabel"
                                                for="module"
                                                >নাসির বিন বুরহান</label
                                              >
                                            </div>
                                            <div class="filter_item">
                                              <input
                                                type="radio"
                                                name="subcategory"
                                                id="module"
                                                class="filterinput"
                                              />
                                              <label
                                                class="filterlabel"
                                                for="module"
                                                >আওলাদ হোসেন</label
                                              >
                                            </div>
                                            <div class="filter_item">
                                              <input
                                                type="radio"
                                                name="subcategory"
                                                id="module"
                                                class="filterinput"
                                              />
                                              <label
                                                class="filterlabel"
                                                for="module"
                                                >মনির হোসেন</label
                                              >
                                            </div>
                                            <div class="filter_item">
                                              <input
                                                type="radio"
                                                name="subcategory"
                                                id="module"
                                                class="filterinput"
                                              />
                                              <label
                                                class="filterlabel"
                                                for="module"
                                                >জুবায়ের হোসেন</label
                                              >
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="accordion-item">
                                      <h2
                                        class="accordion-header"
                                        id="headingOne"
                                      >
                                        <button
                                          class="accordion-button"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target="#collapsesix"
                                          aria-expanded="true"
                                          aria-controls="collapseOne"
                                        >
                                          <div class="accordion_name">
                                            <p>লেভেল</p>
                                          </div>
                                        </button>
                                      </h2>
                                      <div
                                        id="collapsesix"
                                        class="accordion-collapse collapse show"
                                        aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample"
                                      >
                                        <div class="accordion-body">
                                          <div class="filter_box">
                                            <div class="filter_item">
                                              <input
                                                type="radio"
                                                name="subcategory"
                                                id="module"
                                                class="filterinput"
                                              />
                                              <label
                                                class="filterlabel"
                                                for="module"
                                                >অল লেভেল (২৫০)</label
                                              >
                                            </div>
                                            <div class="filter_item">
                                              <input
                                                type="radio"
                                                name="subcategory"
                                                id="module"
                                                class="filterinput"
                                              />
                                              <label
                                                class="filterlabel"
                                                for="module"
                                                >বিগেনার (২০০)</label
                                              >
                                            </div>
                                            <div class="filter_item">
                                              <input
                                                type="radio"
                                                name="subcategory"
                                                id="module"
                                                class="filterinput"
                                              />
                                              <label
                                                class="filterlabel"
                                                for="module"
                                                >ইন্টারমেডিয়েট (৫০)</label
                                              >
                                            </div>
                                            <div class="filter_item">
                                              <input
                                                type="radio"
                                                name="subcategory"
                                                id="module"
                                                class="filterinput"
                                              />
                                              <label
                                                class="filterlabel"
                                                for="module"
                                                >এক্সপার্ট (২০)</label
                                              >
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </nav>
                    </div>
                    <a
                      class="active"
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      ডিজাইন
                    </a>
                  </div>
                </li>
              </ul>
            </div>
            <div class="tab-content" id="pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
                tabindex="0"
              >
                <div class="department_course_wrapper">
                  <a class="department_left_arrow depart_left"
                    ><i class="fa fa-chevron-left" aria-hidden="true"></i
                  ></a>
                  <a class="department_right_arrow depart_right"
                    ><i class="fa fa-chevron-right" aria-hidden="true"></i
                  ></a>
                  <div class="row course_wrapper_department tab_department">
                    <div class="col-xl-4" v-for="(course, i) in 3" :key="i">
                      <CourseCard />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="filters" class="mt-5">
      <div class="container">
        <div class="row">
          <div class="col-xl-3 col-lg-3">
            <div class="filter-sidebar d-none d-lg-block d-xl-block">
              <h3>ফিল্টার</h3>
              <div class="filter_items px-2">
                <div class="accordion" id="accordionExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        <div class="accordion_name">
                          <p>সাবক্যাটাগরি</p>
                        </div>
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div class="filter_box">
                          <div class="filter_item">
                            <input
                              type="radio"
                              name="subcategory"
                              id="module"
                              class="filterinput"
                            />
                            <label class="filterlabel" for="module"
                              >ওয়েব ডিজাইন</label
                            >
                          </div>
                          <div class="filter_item">
                            <input
                              type="radio"
                              name="subcategory"
                              id="module2"
                              class="filterinput"
                            />
                            <label class="filterlabel" for="module2"
                              >ওয়েব ডিজাইন</label
                            >
                          </div>
                          <div class="filter_item">
                            <input
                              type="radio"
                              name="subcategory"
                              id="module3"
                              class="filterinput"
                            />
                            <label class="filterlabel" for="module3"
                              >ওয়েব ডিজাইন</label
                            >
                          </div>
                          <div class="filter_item">
                            <input
                              type="radio"
                              name="subcategory"
                              id="module"
                              class="filterinput"
                            />
                            <label class="filterlabel" for="module"
                              >ওয়েব ডিজাইন</label
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsetwo"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        <div class="accordion_name">
                          <p>রিভিউ</p>
                        </div>
                      </button>
                    </h2>
                    <div
                      id="collapsetwo"
                      class="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div class="filter_box">
                          <div class="filter_item">
                            <input
                              type="radio"
                              name="subcategory"
                              id="module"
                              class="filterinput"
                            />
                            <label class="starlabel" for="module"
                              ><i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                            </label>
                          </div>
                          <div class="filter_item">
                            <input
                              type="radio"
                              name="subcategory"
                              id="module"
                              class="filterinput"
                            />
                            <label class="starlabel" for="module"
                              ><i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                            </label>
                          </div>
                          <div class="filter_item">
                            <input
                              type="radio"
                              name="subcategory"
                              id="module"
                              class="filterinput"
                            />
                            <label class="starlabel" for="module"
                              ><i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                            </label>
                          </div>
                          <div class="filter_item">
                            <input
                              type="radio"
                              name="subcategory"
                              id="module"
                              class="filterinput"
                            />
                            <label class="starlabel" for="module"
                              ><i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsefour"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        <div class="accordion_name">
                          <p>প্রাইজ</p>
                        </div>
                      </button>
                    </h2>
                    <div
                      id="collapsefour"
                      class="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div class="filter_box">
                          <div class="filter_item">
                            <input
                              type="radio"
                              name="subcategory"
                              id="module"
                              class="filterinput"
                            />
                            <label class="filterlabel" for="module"
                              >পেইড (২৫০)</label
                            >
                          </div>
                          <div class="filter_item">
                            <input
                              type="radio"
                              name="subcategory"
                              id="module"
                              class="filterinput"
                            />
                            <label class="filterlabel" for="module"
                              >ফ্রি (২৫)</label
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsethree"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        <div class="accordion_name">
                          <p>ইন্সট্রাকটর</p>
                        </div>
                      </button>
                    </h2>
                    <div
                      id="collapsethree"
                      class="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div class="filter_box">
                          <div class="filter_item">
                            <input
                              type="radio"
                              name="subcategory"
                              id="module"
                              class="filterinput"
                            />
                            <label class="filterlabel" for="module"
                              >নাসির বিন বুরহান</label
                            >
                          </div>
                          <div class="filter_item">
                            <input
                              type="radio"
                              name="subcategory"
                              id="module"
                              class="filterinput"
                            />
                            <label class="filterlabel" for="module"
                              >আওলাদ হোসেন</label
                            >
                          </div>
                          <div class="filter_item">
                            <input
                              type="radio"
                              name="subcategory"
                              id="module"
                              class="filterinput"
                            />
                            <label class="filterlabel" for="module"
                              >মনির হোসেন</label
                            >
                          </div>
                          <div class="filter_item">
                            <input
                              type="radio"
                              name="subcategory"
                              id="module"
                              class="filterinput"
                            />
                            <label class="filterlabel" for="module"
                              >জুবায়ের হোসেন</label
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsesix"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        <div class="accordion_name">
                          <p>লেভেল</p>
                        </div>
                      </button>
                    </h2>
                    <div
                      id="collapsesix"
                      class="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div class="filter_box">
                          <div class="filter_item">
                            <input
                              type="radio"
                              name="subcategory"
                              id="module"
                              class="filterinput"
                            />
                            <label class="filterlabel" for="module"
                              >অল লেভেল (২৫০)</label
                            >
                          </div>
                          <div class="filter_item">
                            <input
                              type="radio"
                              name="subcategory"
                              id="module"
                              class="filterinput"
                            />
                            <label class="filterlabel" for="module"
                              >বিগেনার (২০০)</label
                            >
                          </div>
                          <div class="filter_item">
                            <input
                              type="radio"
                              name="subcategory"
                              id="module"
                              class="filterinput"
                            />
                            <label class="filterlabel" for="module"
                              >ইন্টারমেডিয়েট (৫০)</label
                            >
                          </div>
                          <div class="filter_item">
                            <input
                              type="radio"
                              name="subcategory"
                              id="module"
                              class="filterinput"
                            />
                            <label class="filterlabel" for="module"
                              >এক্সপার্ট (২০)</label
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-9 col-lg-9 col-md-12 col-12">
            <div class="row">
              <div
                class="col-xl-12 col-12 col-lg-12"
                v-for="(item, i) in 2"
                :Key="i"
              >
                <div class="card-offer mb-5">
                  <div class="row">
                    <div class="col-xl-4 col-lg-4">
                      <div class="card-cap">
                        <img
                          width="100%"
                          src="https://admin.brightskills.com/media/course/Graphic%20Design%20in%20Marketing%20%26%20Advertising/graphic-design-in-marketing-and-advertising-558px-364px.jpg"
                          alt="dd"
                        />
                      </div>
                    </div>
                    <div class="col-xl-8 col-lg-8">
                      <div class="card-content">
                        <div class="hover-content">
                          <div class="detailbox">
                            <div class="detail-info">
                              <h4 class="title">
                                Adobe Photoshop Master Course
                              </h4>
                              <p class="trainername mt-3">
                                প্রশিক্ষকঃ<a href="#"> Albert Tonmoy Gomes</a>
                              </p>
                              <p class="durations" style="color: #ff8079">
                                টোটাল ০৩ ঘন্টা - বিগিনার লেভেল
                              </p>
                              <p class="detail">
                                বিশ্বের প্রায় ৯০% ক্রিয়েটিভ প্রফেশনাল Adobe
                                Photoshop ব্যবহার করে। এই সফটওয়্যার দিয়ে গ্রাফিক
                                ডিজাইন কাজ ছাড়াও ওয়েব ডিজাইন, ফটোগ্রাফি, থ্রিডি
                                অ্যানিমেশন, ভিডিও প্রোডাকশনের কাজ করা যায়। প্রায়
                                প্রতিটি ম ...
                              </p>
                              <div class="detail-list">
                                <ul>
                                  <li>
                                    <i
                                      class="fa fa-check"
                                      aria-hidden="true"
                                    ></i
                                    >৫ ঘন্টার অধিক ভিডিও
                                  </li>
                                  <li>
                                    <i
                                      class="fa fa-check"
                                      aria-hidden="true"
                                    ></i
                                    >৫ ঘন্টার অধিক ভিডিও
                                  </li>
                                  <li>
                                    <i
                                      class="fa fa-check"
                                      aria-hidden="true"
                                    ></i
                                    >৫ ঘন্টার অধিক ভিডিও
                                  </li>
                                  <li>
                                    <i
                                      class="fa fa-check"
                                      aria-hidden="true"
                                    ></i
                                    >৫ ঘন্টার অধিক ভিডিও
                                  </li>
                                  <li>
                                    <i
                                      class="fa fa-check"
                                      aria-hidden="true"
                                    ></i
                                    >৫ ঘন্টার অধিক ভিডিও
                                  </li>
                                  <li>
                                    <i
                                      class="fa fa-check"
                                      aria-hidden="true"
                                    ></i
                                    >৫ ঘন্টার অধিক ভিডিও
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div class="button-box">
                              <router-link to="/checkout">
                                <button class="btn-learn my-2">
                                  শেখা শুরু করুন
                                </button></router-link
                              >
                              <div class="buttons">
                                <a href="#" class="favourite"
                                  ><i
                                    class="fa fa-heart-o"
                                    style="font-size: 26px"
                                    aria-hidden="true"
                                  ></i>
                                  ফেভারিট</a
                                >
                                <button class="cart">
                                  <i
                                    class="fa fa-shopping-bag"
                                    aria-hidden="true"
                                  ></i>
                                  কার্টে যুক্ত করুন
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <h3>Graphic Design in Marketing & Advertising</h3>
                        <p>
                          প্রশিক্ষকঃ
                          <a href="#">Mohammad Ullah Rocky</a>
                        </p>
                        <div class="course_card_offer_wrapper">
                          <div class="course_card_rating_number">
                            <span>৫</span>
                            <ul>
                              <li>
                                <i class="fa fa-star" aria-hidden="true"></i>
                              </li>
                              <li>
                                <i class="fa fa-star" aria-hidden="true"></i>
                              </li>
                              <li>
                                <i class="fa fa-star" aria-hidden="true"></i>
                              </li>
                              <li>
                                <i class="fa fa-star" aria-hidden="true"></i>
                              </li>
                              <li>
                                <i class="fa fa-star" aria-hidden="true"></i>
                              </li>
                            </ul>
                          </div>
                          <div class="rate-price">
                            <span class="current_price"> ১২৯৯ টাকা </span>
                            <span class="previous_price"> ৩০০০ </span>
                            <span class="discount">(৫৬% ছাড়)</span>
                          </div>
                          <router-link to="/checkout"
                            >শেখা শুরু করুন</router-link
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import CourseCard from "@/components/partials/courseCard/courseCard.vue";
export default {
  components: {
    CourseCard,
  },
  created() {
    this.$router.currentRoute.path; // path is /post
  },
};
</script>
