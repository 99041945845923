<template>
  <section id="popular_category">
    <div class="container">
      <div class="row">
        <div class="col-xl-6 m-auto">
          <div class="title_header">
            <h2>বেছে নিন আপনার পছন্দের ক্যাটাগরি</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12">
          <div class="category">
            <a
              href="#"
              class="category_card"
              v-for="(category, index) in popular_categories"
              :key="index"
            >
              <div class="category_card_img">
                <img width="50" :src="category.img" alt="img" />
              </div>
              <div class="category_card_content">
                <h4>{{ category.title }}</h4>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      popular_categories: [
        {
          img: "https://admin.brightskills.com/media/images/design.png",
          title: "ডিজাইন",
        },
        {
          img: "https://admin.brightskills.com/media/images/digital_marketing.png",
          title: "ডিজিটাল মার্কেটিং",
        },
        {
          img: "https://admin.brightskills.com/media/images/web.png",
          title: "ওয়েব প্রোগ্রামিং",
        },
        {
          img: "https://admin.brightskills.com/media/images/Robotics___Automation.png",
          title: "রোবটিক্স",
        },
        {
          img: "https://admin.brightskills.com/media/images/MS_Office.png",
          title: "মাইক্রোসফট ",
        },
      ],
    };
  },
};
</script>
