<template>
  <section id="banner">
    <div class="row">
      <div class="col-xl-12 col-sm-12 col-md-12 col-lg-12">
        <div class="banner_background_img">
          <div class="row">
            <div class="col-xl-6 col-lg-6 col-12 col-md-12 col-sm-12 m-auto">
              <div class="container">
                <div class="banner-content-wrapper">
                  <div class="banner-content">
                    <h1>
                      লক্ষ্য হোক <span style="color: #2b92d0">দক্ষ</span> হওয়া!
                    </h1>
                    <p>
                      ‘সব সম্ভব’- এর এই যুগে আপনি কেনো ‘অসম্ভব’ এর নামতা
                      আওড়াচ্ছেন? এখন ঘরে বসেই নিজের স্কিল ডেভেলপ করুন- সেরা
                      এক্সপার্টদের কাছ থেকে!
                    </p>
                    <a @click="showAlert">সকল কোর্স</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-12 col-md-12 col-sm-12">
              <div class="banner_img_wrapper">
                <img
                  width="100%"
                  src="https://admin.brightskills.com/media/homepage/Website-Header2_1.png"
                  alt="d"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    showAlert() {
      this.$swal.fire({
        title:
          "<strong>Sorry for the inconvenience. Our website is currently undergoing scheduled maintenance. We apologize for any inconvenience this may cause. Please check back later</strong>",
        icon: "info",
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText: false,
        cancelButtonText: false,
        padding: "3em",
        width: 600,
      });
    },
  },
};
</script>
