<template>
   <div style="position:absolute;top:0;left:0;width:100%;height:100%;">
	<vimeo-player
              ref="player"
              v-if="videoId != ''&& type == 1"
              :video-id="videoId"
              @play="play()"
              @pause="pause()"
              width="320"
              height="180"
              @ready="onReady"
              class="embed-container" 
              :options="options"
    />
	<youtube  v-if="videoId != ''&& type == 2" :video-id="videoId" :player-vars="playerVars"
	@ready="onReady" :icon="customIcon" ref="player"
	style="position:absolute;top:0;left:0;width:100%;height:100%;"
	></youtube>  
	<button class="btn-playpause" 
	@click.prevent="play()"
    v-if="!playing && type==1" >
     <svg width="130" height="130" viewBox="0 0 130 130" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="65" cy="65" r="65" fill="white" fill-opacity="0.1"></circle>
            <circle cx="65.0001" cy="65.0001" r="50.2033" fill="white" fill-opacity="0.2"></circle>
            <path fill-rule="evenodd" clip-rule="evenodd"
            d="M65.529 95.1225C73.3777 95.1225 80.9049 92.0047 86.4548 86.4548C92.0047 80.9049 95.1225 73.3777 95.1225 65.529C95.1225 57.6804 92.0047 50.1531 86.4548 44.6033C80.9049 39.0534 73.3777 35.9355 65.529 35.9355C57.6804 35.9355 50.1531 39.0534 44.6033 44.6033C39.0534 50.1531 35.9355 57.6804 35.9355 65.529C35.9355 73.3777 39.0534 80.9049 44.6033 86.4548C50.1531 92.0047 57.6804 95.1225 65.529 95.1225ZM63.8829 55.0529C63.3258 54.6813 62.6782 54.4678 62.0093 54.4353C61.3404 54.4028 60.6752 54.5525 60.0847 54.8685C59.4942 55.1844 59.0006 55.6547 58.6564 56.2293C58.3123 56.8038 58.1306 57.461 58.1307 58.1307V72.9274C58.1306 73.5971 58.3123 74.2543 58.6564 74.8288C59.0006 75.4034 59.4942 75.8737 60.0847 76.1896C60.6752 76.5056 61.3404 76.6553 62.0093 76.6228C62.6782 76.5903 63.3258 76.3768 63.8829 76.0051L74.9805 68.6068C75.4871 68.2689 75.9025 67.8113 76.1898 67.2744C76.4771 66.7375 76.6274 66.138 76.6274 65.529C76.6274 64.9201 76.4771 64.3206 76.1898 63.7837C75.9025 63.2468 75.4871 62.7891 74.9805 62.4513L63.8829 55.0529Z"
            fill="url(#paint0_linear)">
            </path>
            <defs>
            <linearGradient id="paint0_linear" x1="65.529" y1="35.9355" x2="65.529" y2="95.1225"
             gradientUnits="userSpaceOnUse">
            <stop stop-color="#FFF1E3"></stop>
            <stop offset="1" stop-color="#E8B586"></stop>
             </linearGradient>
            </defs>
    </svg>
    </button>
  </div>
</template>

<script>

export default {
    props: {
        v_id: String,
        type: String,
  },
	data() {
		return {
			playing: false,
			videoId: this.v_id,
			height: 500,
			options: {
					muted: true,
					autoplay: true,
					sidedock:0,
					color: 'white',
					responsive: true
			},
			playerReady: false,
			playerVars: {
				autoplay: 1,
				title: 0,
				color: 'white',
				modestbranding: 1,
				'showinfo': 0,
				'iv_load_policy': 0,
				'cc_load_policy': 0, 
				'fs': 1, 
				'rel': 0, 
				showshare: 0,
				cc_lang_pref:0,
				widget_referrer:0,
				captions:0
			},
			customIcon: {
				play: 'https://www.bubt.edu.bd/assets/frontend/images/bubt_logo.png',
				pause: 'https://www.bubt.edu.bd/assets/frontend/images/bubt_logo.png',
				volume: 'https://www.bubt.edu.bd/assets/frontend/images/bubt_logo.png',
				mute: 'path_to_custom_mute_icon',
				fullscreen: 'https://www.bubt.edu.bd/assets/frontend/images/bubt_logo.png',
				exitFullscreen: 'https://www.bubt.edu.bd/assets/frontend/images/bubt_logo.png',
			},
		}
	},
// 	mounted(event) {
//     this.player =  this.player = event.target;
//   },

computed: {
    player() {
      return this.$refs.player.player
    }
  },
	methods: {
	onReady() {
      this.player = false;
    },
	play() {
			this.player.play()
		this.playing = true;
    },
	pause() {
      this.playing = !this.playing ;
    },
  },
	// 	playing() {
    //   console.log(' we are watching!!!')
    //   }
	}
</script>

<style >
/*=================video player css start======================*/
.custom-player {
	border: 1px solid #ccc;
    border-radius: 30px;
    box-shadow: 0px 0px 5px #ccc;
    width: 50%;
}
.custom-player .ytp-iv-logo {
  display: none !important;
}
iframe{
	margin: 0 auto;
	width: 50%;
	position: relative;
}
iframe::after{
	position: absolute;
	content:'';
	left:0;
	bottom: 0;
	top: 0;
	width:100%;
	height: 100%;
	background: red;
	z-index: 9999999;
}
/*=================video player css end======================*/
</style>