var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{attrs:{"id":"course_tabs"}},[_vm._m(0),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12 col-lg-12 col-sm-12 col-md-12"},[_c('div',{staticClass:"course_tab_wrappers"},[_c('div',{staticClass:"course-tabs position-relative"},[_vm._m(1),_vm._m(2),_c('ul',{staticClass:"nav-pills tabs_slider",attrs:{"id":"pills-tab","role":"tablist"}},[_c('VueSlickCarousel',_vm._b({},'VueSlickCarousel',_vm.settings,false),_vm._l((_vm.department),function(item,i){return _c('li',{key:'department_' + i,staticClass:"nav-item active",attrs:{"role":"presentation"},on:{"click":function($event){return _vm.departmentIndexFind(i)}}},[_c('a',{attrs:{"id":"pills-home-tab","data-bs-toggle":"pill","data-bs-target":"#pills-home","role":"tab","aria-controls":"pills-home","aria-selected":"true"}},[_c('div',{staticClass:"tab-card"},[_c('div',{staticClass:"card_img"},[_c('img',{attrs:{"width":"50","src":item.icon,"alt":"card"}})]),_c('div',{staticClass:"card_text"},[_c('p',[_vm._v(_vm._s(item.name))])])])])])}),0)],1)])]),_vm._l((1),function(item,departmentIndex){return _c('div',{key:'departmentIndex' + departmentIndex,staticClass:"tab-content",attrs:{"id":"pills-tabContent"}},[_c('div',{staticClass:"tab-pane fade show active",attrs:{"id":"pills-home","role":"tabpanel","aria-labelledby":"pills-home-tab","tabindex":"0"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-6 col-lg-6 col-12 col-sm-12"},[_c('router-link',{attrs:{"to":'/course-details/' +
                    (departmentIndex + 1) +
                    '/' +
                    (_vm.indexCount + 2)}},[_c('div',{staticClass:"card_wrapper_course"},[_c('div',{staticClass:"card_big_course"},[_c('div',{staticClass:"card_big_course_img"},[_c('img',{staticClass:"card-img-top",attrs:{"width":"100%","src":_vm.department[_vm.indexCount]?.department_courses[0]?.img,"alt":"course_img"}})]),_c('div',{staticClass:"card_big_course_content"},[_c('p',[_vm._v(" "+_vm._s(_vm.department[_vm.indexCount]?.department_courses[0] .course_name)+" ")]),_c('h5',{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.department[_vm.indexCount]?.department_courses[0] ?.course_title)+" ")]),_c('p',{staticClass:"category_description"},[_vm._v(" "+_vm._s(_vm.department[ _vm.indexCount ]?.department_courses[0]?.course_description.slice( 0, 300 ))+"... ")]),_c('div',{staticClass:"ratings d-flex justify-content-between pb-4"},[_c('div',{staticClass:"ratings_number d-flex"},[_c('span',[_vm._v("৫ ")]),_c('ul',[_c('li',[_c('i',{staticClass:"fa fa-star",attrs:{"aria-hidden":"true"}})]),_c('li',[_c('i',{staticClass:"fa fa-star",attrs:{"aria-hidden":"true"}})]),_c('li',[_c('i',{staticClass:"fa fa-star",attrs:{"aria-hidden":"true"}})]),_c('li',[_c('i',{staticClass:"fa fa-star",attrs:{"aria-hidden":"true"}})]),_c('li',[_c('i',{staticClass:"fa fa-star",attrs:{"aria-hidden":"true"}})])])]),_c('div',{staticClass:"course_fee"},[_c('span',[_vm._v(" "+_vm._s(_vm.department[_vm.indexCount]?.department_courses[0] ?.price)+" টাকা")])])])])])])])],1),_c('div',{staticClass:"col-xl-6 col-lg-6 col-12 col-sm-12"},[_c('div',{staticClass:"row"},_vm._l((_vm.department[
                      _vm.indexCount
                    ].department_courses.slice(1)),function(c,i){return _c('div',{key:'course_' + i,staticClass:"col-xl-6 col-12 col-sm-6"},[_c('router-link',{attrs:{"to":'/course-details/' + i}},[_c('courseCard',{attrs:{"id":i + 1,"index":_vm.indexCount + 1,"course":c}})],1)],1)}),0)])])])])})],2)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-6 col-12 col-sm-12 col-md-12 m-auto"},[_c('div',{staticClass:"title_header"},[_c('h2',{staticClass:"course_tab_heading"},[_c('span',{staticStyle:{"color":"#2b92d0"}},[_vm._v("জব-মার্কেটে")]),_vm._v(" নিজেকে চাহিদাসম্পন্ন করে তুলুন- এখনই! ")]),_c('p',[_vm._v(" প্রতিযোগিতামূলক এই জব-মার্কেটে নিজের ক্যারিয়ারকে নিয়ে যান অনন্য উচ্চতায়। আপনার জন্য প্রয়োজনীয় সব ক্যাটাগরিই রয়েছে এখানে। বেছে নিন আপনার সবচেয়ে পছন্দের কোর্সটি। ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"left_arrow"},[_c('i',{staticClass:"fa fa-chevron-left",attrs:{"aria-hidden":"true"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"right_arrow"},[_c('i',{staticClass:"fa fa-chevron-right",attrs:{"aria-hidden":"true"}})])
}]

export { render, staticRenderFns }