import Index from '@/pages/IndexPage.vue'
import SearchResult from '@/pages/SearchResult.vue'
import CartPage from '@/pages/CartPage.vue'
import CheckoutPage from '@/pages/CheckoutPage.vue'
import StudentFeedback from '@/pages/StudentFeedback.vue'
import SingleTeacherCourse from '@/pages/SingleTeacherCourse.vue'
import CourseDetails from '@/pages/CourseDetails.vue'
import LoginPage from '@/pages/LoginPage.vue'
import SignUp from '@/pages/SignUp.vue'



export const routes=[
    {
        path:'/',
        component:Index
    },
    {
        path:'/all-course',
        component:SearchResult
    },
    {
        path:'/search',
        component:SearchResult
    },
    {
        path:'/course-category',
        component:SearchResult
    },
    {
        path:'/single-course-category',
        component:SearchResult
    },
    {
        path:'/student-feedback',
        component:StudentFeedback
    },
    {
        path:'/single-teacher-course',
        component:SingleTeacherCourse
    },
    {
        path:'/cart',
        component:CartPage
    },
    {
        path:'/checkout',
        component:CheckoutPage
    },
    {
        path:'/course-details/:courseIndex/:departmentIndex',
        component:CourseDetails,
        props:{ name: 'example value' }
    },
    {
        path:'/login',
        component:LoginPage
    },
    {
        path:'/signup',
        component:SignUp
    },
   
]