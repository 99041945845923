<template>
  <section id="testimonial">
    <div class="container">
      <div class="row">
        <div class="col-xl-6 m-auto">
          <div class="title_header">
            <h2>
              ব্রাইটস্কিলস-ই দেশ সেরা ই-লার্নিং প্লাটফর্ম। দেখে নেয়া যাক- কি
              বলছে <span style="color: #2b92d0">আমাদের শিক্ষার্থীরা?</span>
            </h2>
          </div>
        </div>
      </div>
      <div class="testimonial_wrapper position-relative">
        <a class="testimonial_left"
          ><i class="fa fa-chevron-left" aria-hidden="true"></i
        ></a>
        <a class="testimonial_right"
          ><i class="fa fa-chevron-right" aria-hidden="true"></i
        ></a>
        <VueSlickCarousel v-bind="setting" ref="testimonial.name">
          <div
            class="row testimonial_main_slider"
            v-for="(item, i) in feedback"
            :key="i"
          >
            <div class="col-xl-12">
              <div class="card_testimonial mx-3">
                <div class="row">
                  <div class="col-xl-2">
                    <div class="card_testimonial_img">
                      <img :src="item.img" alt="img" />
                      <div class="card_testimonial_quote">
                        <img
                          src="https://www.brightskills.com/_nuxt/img/quote.dde38d0.svg"
                          alt="qoute"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-10">
                    <div class="card_testimonial_description">
                      <h5>{{ item.title }}</h5>
                      <div class="course_card_rating_number">
                        <ul>
                          <li><i class="fa fa-star" aria-hidden="true"></i></li>
                          <li><i class="fa fa-star" aria-hidden="true"></i></li>
                          <li><i class="fa fa-star" aria-hidden="true"></i></li>
                          <li><i class="fa fa-star" aria-hidden="true"></i></li>
                          <li><i class="fa fa-star" aria-hidden="true"></i></li>
                        </ul>
                      </div>
                      <p>{{ item.description }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </VueSlickCarousel>
      </div>
    </div>
  </section>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
  components: { VueSlickCarousel },
  data() {
    return {
      feedback: [
        {
          img: "https://admin.brightskills.com/media/homepage/we_are_best/2021-12-27.png",
          description:
            "ফ্রিল্যান্সিং নিয়ে আমার তেমন জানা ছিলো না। ব্রাইট স্কিলস এর Freelancing with Adobe Illustrator কোর্সটি করে ডিজাইন ও প্রজেক্ট সম্পর্কে ধারণা পেয়েছি। কোর্স শেষে অনলাইন মার্কেটপ্লেসে কাজ করার গাইডলাইন পেয়েছি।",
          title: "‘‘কম সময়ে মূল বিষয়ে শিখেছি ’’",
        },
        {
          img: "https://admin.brightskills.com/media/homepage/we_are_best/2021-12-27_bTrnyQQ.jpg",
          description:
            "আমি কয়েকটি লার্নিং প্লাটফর্মে ক্লাস করেছি অনেক সময় প্রাসঙ্গিক কথাকে অনেক দীর্ঘ সময় নিয়ে আলোচনা করায় মূল পয়েন্ট থেকে মনযোগ বিচ্যুত হয়েছে কিন্তু ব্রাইট স্কিলস এর কোর্সগুলো একদম মূল বিষয়ের উপর ফোকাস করে তৈরি করা হয়েছে।",
          title: "‘‘এখন ফ্রিল্যান্সিং কাজ করছি’’",
        },
        {
          img: "https://admin.brightskills.com/media/homepage/we_are_best/images.jpg",
          description:
            "আমরা সাধারনত বিভিন্ন টিউটোরিয়ালগুলো অনলাইনে ইংরেজিতে পেয়ে থাকি। তবে ব্রাইট স্কিলস প্লাটফর্মে আমি এখন আন্তর্জাতিক মানের কোর্স শিখতে পারছি সহজেই।",
          title: "‘‘আন্তর্জাতিকমানের কোর্স শিখেছি বাংলাতে ’’",
        },
      ],
      setting: {
        dots: false,
        infinite: false,
        autoplay: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    };
  },
  methods: {
    Next() {
      this.$refs.testimonial.name.next();
    },
    Prev() {
      this.$refs.testimonial.name.prev();
    },
  },
};
</script>
