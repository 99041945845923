import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import {routes} from './routes'
import vuetify from './plugins/vuetify'
import vueVimeoPlayer from 'vue-vimeo-player'
import VueYoutube from 'vue-youtube'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import 'sweetalert2/dist/sweetalert2.min.css';
import VueSweetalert2 from 'vue-sweetalert2';

require('@/assets/css/style.css');
Vue.config.productionTip = false
Vue.use(VueSweetalert2);
Vue.use(VueRouter)
Vue.use(vueVimeoPlayer)
Vue.use(VueYoutube)
const router=new VueRouter({
  mode:"history",
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})
new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
