<template>
    <div id="cart_page">
        <section id="single-breadcumb">
        <div class="container">
                <div class="row">
                    <div class="col-xl-8">
                        <div class="single_breadcumb_content">
                            <h3>কার্ট</h3>
                            <span>কার্টে ৭ টি কোর্স যুক্ত রয়েছে</span>
                        </div>
                    </div>
                </div>
        </div>
    </section>
    <section id="cart">
        <div class="container">
           <div class="row">
            <div class="col-xl-8 col-lg-8" >
                <div v-for="(cartCourse,i) in 7" :key="i">
                    <CartPrice />
                </div>
               
            </div>
            <div class="col-xl-4 col-lg-4">
                <div class="cart_price_content">
                    <h3>বিস্তারিত মূল্য</h3>
                    <div class="cart_prices">
                        <p class="title">কোর্স মূল্য</p>
                        <p class="price">১১৯০ টাকা</p>
                    </div>
                    <hr style="margin: 0;">
                    <div class="cart_prices">
                        <p class="title">মোট মূল্য</p>
                        <p class="price">১১৯০ টাকা</p>
                    </div>
                    <p class="cart_desp">
                        ব্রাইট স্কিলস প্ল্যাটফর্মের কোর্সসমূহ ইন্ডাস্ট্রির দক্ষ ও অভিজ্ঞ মেন্টর দ্বারা প্রণয়ন করা হয়। আধুনিক  কারিকুলাম ও সহজ উপায়ে বোধগম্য লেসন উপস্থাপনের জন্য এ প্ল্যাটফর্মটি শিক্ষার্থীদের মাঝে জনপ্রিয় হয়ে উঠেছে। সুতরাং, যোগ দিন সেরাদের সাথে।
                    </p>
                    <a href="#" class="cart_price_button">
                        কোর্স কিনুন
                    </a>
                </div>
            </div>
           </div>
        </div>
    </section>
    <section id="department_courses">
        <div class="container">
            <div class="row">
                <div class="col-xl-12">
                    <div class="department_courses_header related_course mt-5">
                        <h3>রিলেটেড কোর্স</h3>                 
                    </div>
                </div>
            </div>
            <div class="department_course_wrapper">
                <a class="department_left_arrow"><i class="fa fa-chevron-left " aria-hidden="true"></i></a>
                <a class="department_right_arrow"><i class="fa fa-chevron-right " aria-hidden="true"></i></a>

                <div class="row course_wrapper_department">
                    <div class="col-xl-4" v-for="(c,i) in 6" :Key="i">
                        <CourseCard/>
                    </div>
                </div>
            </div>

        </div>
    </section>
    </div>
</template>
<script>
    import CourseCard from '@/components/partials/courseCard/courseCard.vue';
    import CartPrice from '@/components/partials/cart/cartPrice.vue';


export default ({
    components:{CourseCard,CartPrice}
})
</script>
