<template>
  <div>
    <NavbarSection></NavbarSection>
    <router-view></router-view>
   <FooterSection/>
  </div>
</template>

<script>
import NavbarSection from "@/components/partials/index/NavbarSection.vue";
import FooterSection from  '@/components/partials/footer/footerSection.vue';
export default {
  name: "App",

  components: {
    NavbarSection,
    FooterSection
  },
  data: () => ({
    //
  }),
};
</script>
