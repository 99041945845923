<template>
  <div>
    <section id="single-banner">
      <div class="container">
        <section id="teacher_course_intro">
          <div class="row">
            <div class="col-xl-2 col-lg-2 col-12">
              <div class="teacher_img">
                <img
                  width="100%"
                  src="https://admin.brightskills.com/media/instructor/profile/Burhanul/borhan_sir.jpg"
                  alt="img"
                />
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-12">
              <div class="teacher_content">
                <h4>Burhanul Islam Sazib</h4>
                <p>3D Model Artist</p>
              </div>
            </div>
            <div class="col-xl-5 col-lg-5 col-12">
              <div class="teacher_wrapper d-flex">
                <div class="teacher_course text-end me-3">
                  <h4>১</h4>
                  <h4>কোর্স</h4>
                </div>
                <div class="teacher_rating">
                  <h4>৫</h4>
                  <div class="star">
                    <ul>
                      <li v-for="(rating, i) in 5" :key="i">
                        <i class="fa fa-star" aria-hidden="true"></i>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
    <section id="teacher_course">
      <div class="container">
        <div class="card-teacher-wrapper" v-for="(c, i) in 2" :key="i">
          <h3 class="teacher_department mt-5">নিউ কোর্স</h3>
          <div class="card-offer mb-5">
            <div class="row my-5">
              <div class="col-xl-12 col-12 col-lg-12">
                <router-link :to="'/CourseDetails/' + i">
                  <div class="card-offer">
                    <div class="row">
                      <div class="col-xl-4 col-lg-4">
                        <div class="card-cap">
                          <img
                            width="100%"
                            src="https://admin.brightskills.com/media/course/Graphic%20Design%20in%20Marketing%20%26%20Advertising/graphic-design-in-marketing-and-advertising-558px-364px.jpg"
                            alt="dd"
                          />
                        </div>
                      </div>
                      <div class="col-xl-8 col-lg-8">
                        <div class="card-content">
                          <div class="hover-content">
                            <div class="detailbox">
                              <div class="detail-info">
                                <h4 class="title">
                                  Adobe Photoshop Master Course
                                </h4>
                                <p class="trainername mt-3">
                                  প্রশিক্ষকঃ<a href="#"> Albert Tonmoy Gomes</a>
                                </p>
                                <p class="durations" style="color: #ff8079">
                                  টোটাল ০৩ ঘন্টা - বিগিনার লেভেল
                                </p>
                                <p class="detail">
                                  বিশ্বের প্রায় ৯০% ক্রিয়েটিভ প্রফেশনাল Adobe
                                  Photoshop ব্যবহার করে। এই সফটওয়্যার দিয়ে
                                  গ্রাফিক ডিজাইন কাজ ছাড়াও ওয়েব ডিজাইন,
                                  ফটোগ্রাফি, থ্রিডি অ্যানিমেশন, ভিডিও
                                  প্রোডাকশনের কাজ করা যায়। প্রায় প্রতিটি ম ...
                                </p>
                                <div class="detail-list">
                                  <ul>
                                    <li>
                                      <i
                                        class="fa fa-check"
                                        aria-hidden="true"
                                      ></i
                                      >৫ ঘন্টার অধিক ভিডিও
                                    </li>
                                    <li>
                                      <i
                                        class="fa fa-check"
                                        aria-hidden="true"
                                      ></i
                                      >৫ ঘন্টার অধিক ভিডিও
                                    </li>
                                    <li>
                                      <i
                                        class="fa fa-check"
                                        aria-hidden="true"
                                      ></i
                                      >৫ ঘন্টার অধিক ভিডিও
                                    </li>
                                    <li>
                                      <i
                                        class="fa fa-check"
                                        aria-hidden="true"
                                      ></i
                                      >৫ ঘন্টার অধিক ভিডিও
                                    </li>
                                    <li>
                                      <i
                                        class="fa fa-check"
                                        aria-hidden="true"
                                      ></i
                                      >৫ ঘন্টার অধিক ভিডিও
                                    </li>
                                    <li>
                                      <i
                                        class="fa fa-check"
                                        aria-hidden="true"
                                      ></i
                                      >৫ ঘন্টার অধিক ভিডিও
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div class="button-box">
                                <button class="btn-learn my-2">
                                  শেখা শুরু করুন
                                </button>
                                <div class="buttons">
                                  <a href="#" class="favourite"
                                    ><i
                                      class="fa fa-heart-o"
                                      style="font-size: 26px"
                                      aria-hidden="true"
                                    ></i>
                                    ফেভারিট</a
                                  >
                                  <button class="cart">
                                    <i
                                      class="fa fa-shopping-bag"
                                      aria-hidden="true"
                                    ></i>
                                    কার্টে যুক্ত করুন
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <h3>Graphic Design in Marketing & Advertising</h3>
                          <p>
                            প্রশিক্ষকঃ
                            <a href="#">Mohammad Ullah Rocky</a>
                          </p>
                          <div class="course_card_offer_wrapper">
                            <div class="course_card_rating_number">
                              <span>৫</span>
                              <ul>
                                <li>
                                  <i class="fa fa-star" aria-hidden="true"></i>
                                </li>
                                <li>
                                  <i class="fa fa-star" aria-hidden="true"></i>
                                </li>
                                <li>
                                  <i class="fa fa-star" aria-hidden="true"></i>
                                </li>
                                <li>
                                  <i class="fa fa-star" aria-hidden="true"></i>
                                </li>
                                <li>
                                  <i class="fa fa-star" aria-hidden="true"></i>
                                </li>
                              </ul>
                            </div>
                            <div class="rate-price">
                              <span class="current_price"> ১২৯৯ টাকা </span>
                              <span class="previous_price"> ৩০০০ </span>
                              <span class="discount">(৫৬% ছাড়)</span>
                            </div>
                            <router-link to="/checkout"
                              >শেখা শুরু করুন</router-link
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="single_category_tabs_slider">
      <div class="container">
        <div class="row">
          <div class="col-xl-12">
            <div class="single_category_tabs">
              <ul class="nav nav-pills" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                  <div class="single_banner_content">
                    <a
                      class="active"
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      ডিজাইন
                    </a>
                  </div>
                </li>
              </ul>
            </div>
            <div class="tab-content" id="pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
                tabindex="0"
              >
                <div class="row course_wrapper_department tab_department">
                  <div class="col-xl-4" v-for="(c, i) in 3" :key="i">
                    <CourseCard :id="i" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import CourseCard from "@/components/partials/courseCard/courseCard.vue";
export default {
  components: {
    CourseCard,
  },
};
</script>
