<template>
    <div >
        <router-link :to="{ path: '/course-details/'+(id+1)+'/'+(index+1)}">
        <div class="course_wrapper">
                <div class="course_card">
                <div class="course_card_img">
                    <img width="100%"
                        :src="course.img"
                        class="card-img-top" alt="course_img" />
                </div>

                <div class="course_card_content">
                    <p>{{ course.course_name }}</p>
                    <h5>{{ course.course_title }}</h5>
                    <div class="course_card_ratings d-flex justify-content-between">
                        <div class="course_card_rating_number">
                            <span>৫</span>
                            <ul>
                                <li><i class="fa fa-star" aria-hidden="true"></i>
                                </li>
                                <li><i class="fa fa-star" aria-hidden="true"></i>
                                </li>
                                <li><i class="fa fa-star" aria-hidden="true"></i>
                                </li>
                                <li><i class="fa fa-star" aria-hidden="true"></i>
                                </li>
                                <li><i class="fa fa-star" aria-hidden="true"></i>
                                </li>
                            </ul>
                        </div>
                        <div class="course_card_fee">
                            <span class="delete"><del>{{ course.del }} টাকা</del></span>
                            &nbsp;
                            <span>{{course.price}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </router-link>
    </div>
</template>
<script>
export default({
    props:['id','course','index'],
data(){
  return {
   
  }
}
})
</script>